import React from 'react'

import { Row } from 'antd'

import { Pricing } from '../../contracts/page'

import './Pricing.scss'

export interface Props {
	pricing: Pricing
	location: Location
}

export const PricingBlock = (props: Props) => {
	const pricing = props.pricing

	return (
		<Row type="flex" className="c-pricing page-gutter">
			<div className="c-pricing__wrap">
				<div className="c-pricing__content">
					<div className="c-pricing__content__wysiwyg" dangerouslySetInnerHTML={{ __html: pricing.pricingOptionsBlockText }} />
				</div>
			</div>
		</Row>
	)
}

export default PricingBlock
