import React from 'react';

import { Row, Col } from 'antd';

import {SplitContent} from '../../contracts/page';

import './SplitBlock.scss';

export interface Props {
	splitContent: SplitContent;
	location: Location;
}

export const SplitBlock = (props: Props) => {
	const splitContent = props.splitContent;
	return (
		<Row type="flex" className="c-splitBlock">
			<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="left">
				<div className="c-splitBlock__wrap">
					<p className="c-splitBlock__title">
						<img src="../../images/ui/check-circle.svg" alt="Tick" />
						{splitContent.splitLeftTitle}
					</p>
					<div className="c-splitBlock__content"
						 dangerouslySetInnerHTML={{__html: splitContent.splitLeftContent}}/>
				</div>
			</Col>
			<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className="right">
				<div className="c-splitBlock__wrap">
					<p className="c-splitBlock__title">
						<img src="../../images/ui/check-circle.svg" alt="Tick" />
						{splitContent.splitRightTitle}
					</p>
					<div className="c-splitBlock__content"
						 dangerouslySetInnerHTML={{__html: splitContent.splitRightContent}}/>
				</div>
			</Col>
		</Row>
	);
};

export default SplitBlock;
