import React from 'react';

import { Row, Col } from 'antd';

import {LeaseOptions, LeaseOptionsArr} from '../../contracts/page';

import {GatsbyImage} from 'gatsby-plugin-image';
import {IGatsbyImageData} from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

import './LeaseOptions.scss';

export interface Props {
	leaseOptions: LeaseOptions;
	location: Location;
}

export const LeaseOptBlock = (props: Props) => {
	const leaseOptions = props.leaseOptions;
	return (
		<Row type="flex" className="c-leaseOptions page-gutter">
			<Col className="display-flex align-center" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
				<p className="c-leaseOptions__heading">Lease Options</p>
			</Col>
			<div className="c-leaseOptions__wrap">
				{leaseOptions.leaseOptions.length && leaseOptions.leaseOptions.map((item: LeaseOptionsArr, itemIndex: number) => {
					const fluid: IGatsbyImageData | null = (
						item.leaseOptionIcon &&
						item.leaseOptionIcon.localFile &&
						item.leaseOptionIcon.localFile.childImageSharp &&
						item.leaseOptionIcon.localFile.childImageSharp.gatsbyImageData
					) ? item.leaseOptionIcon.localFile.childImageSharp.gatsbyImageData : null;
					return (
						<Col key={itemIndex} className="display-flex" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
							{(fluid !== null) ? (
								<div className="c-leaseOptions__wrapper">
									<div className="c-leaseOptions__item">
										<div className="c-leaseOptions__title">
											<GatsbyImage image={fluid} alt={item.leaseOptionTitle} />
											<span>{item.leaseOptionTitle}</span>
										</div>
										<div className="c-leaseOptions__content"
											 dangerouslySetInnerHTML={{__html: item.leaseOptionContent}}/>
									</div>
								</div>
							) : (
								<div className="c-leaseOptions__wrapper">
									<div className="c-leaseOptions__item">
										<div className="c-leaseOptions__title">
											<img src={item.leaseOptionIcon.localFile?.publicURL} alt={item.leaseOptionTitle} />
											<span>{item.leaseOptionTitle}</span>
										</div>
										<div className="c-leaseOptions__content"
											 dangerouslySetInnerHTML={{__html: item.leaseOptionContent}}/>
									</div>
								</div>
							)}
						</Col>
					);
				})}
			</div>
		</Row>
	);
};

export default LeaseOptBlock;
