import React from 'react'

import { graphql } from 'gatsby'

import { Row, Col } from 'antd'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

import { Page } from '../contracts/page'

import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser'

import scrollTo from 'gatsby-plugin-smoothscroll'

import ContentBlockHtml from '../components/ContentBlock'
import Cta from '../components/Ctas'
import FaqBlock from '../components/Faqs'
import Hero from '../components/Hero'
import LeaseOptBlock from '../components/LeaseOptions'
import PricingBlock from '../components/PricingBlock'
import SplitBlock from '../components/SplitBlock'

import '../styles/welfare-page.scss'

export interface Props {
	data: {
		wpPage: Page
	}
	location: Location
}

function overlayIframe() {
	const iframeOverlay = document.getElementsByClassName('c-van360__overlay')
	console.log(iframeOverlay)
	// @ts-ignore
	iframeOverlay[0].style.pointerEvents = 'none'
}

export const WelfarePage = (props: Props) => {
	const page = props.data.wpPage
	const fluid: IGatsbyImageData | null = (
		props.data &&
		page &&
		page.heroBanner &&
		page.heroBanner.bannerImage &&
		page.heroBanner.bannerImage.localFile &&
		page.heroBanner.bannerImage.localFile.childImageSharp &&
		page.heroBanner.bannerImage.localFile.childImageSharp.gatsbyImageData
	) ? page.heroBanner.bannerImage.localFile.childImageSharp.gatsbyImageData : null
	return (
		<Layout location={props.location}>
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Row type="flex">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} id="primary" className="content-area">
					<div className="welfare">
						<div className="top-banner">
							{(page.heroBanner && page.heroBanner.showBanner === 'yes' && fluid !== null) && (
								<Hero location={props.location} heroBanner={page.heroBanner} fluid={fluid} />
							)}
						</div>
						{(page.contentBlock && page.contentBlock.showContentBlock === 'yes') ? (
							<ContentBlockHtml location={props.location} contentBlock={page.contentBlock} />
						) : ''}
						<Row type="flex" className="page-gutter">
							<Col className="display-flex align-center" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
								<div className="c-tour">
									<a onClick={() => scrollTo('#van360')}>
										<svg xmlns="http://www.w3.org/2000/svg" width="44" height="22" viewBox="0 0 44 22">
											<path id="Arrow_right" data-name="Arrow right" d="M22,0,44,22H0Z" transform="translate(44 22) rotate(180)" fill="#1f9ad0" />
										</svg>
										Take a tour of our sample welfare van below
										<svg xmlns="http://www.w3.org/2000/svg" width="44" height="22" viewBox="0 0 44 22">
											<path id="Arrow_right" data-name="Arrow right" d="M22,0,44,22H0Z" transform="translate(44 22) rotate(180)" fill="#1f9ad0" />
										</svg>
									</a>
								</div>
							</Col>
						</Row>
						<Row type="flex" className="page-gutter">
							<Col className="display-flex align-center" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
								<span className="c-whyChoose">Why choose us?</span>
							</Col>
						</Row>
						{(page.ctas && page.ctas.ctas && page.ctas.ctas.length) ? (
							<Cta location={props.location} ctas={page.ctas} welfare={true} />
						) : ''}
						<div className="c-van360">
							<div className="c-van360__overlay" onClick={overlayIframe}>
								<span className="hide-tablet">Click to interact</span>
								<span className="hide-desktop">Tap to interact</span>
							</div>
							<iframe id="van360" sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin" className={'ant-row'} src="https://tour.klapty.com/7Kz0einTLQ/?deeplinking=true&startscene=0&startactions=lookat(186.36,0,89.99,0,0)" width={'100%'} height={'620px'} />
						</div>
						{(page.leaseOptions && page.leaseOptions.leaseOptions && page.leaseOptions.leaseOptions.length) ? (
							<LeaseOptBlock leaseOptions={page.leaseOptions} location={props.location} />
						) : ''}
						{(page.pricing && page.pricing.pricingOptions && page.pricing.pricingOptions.length) ? (
							<PricingBlock pricing={page.pricing} location={props.location} />
						) : ''}
						<a onClick={() => scrollTo('#contact')} className="c-enquire btn btn-primary">Enquire now</a>
						{(page.faqs && page.faqs.showFaqs === 'yes') ? (
							<FaqBlock location={props.location} showHeading={false} faqs={page.faqs} bgWhite={false} />
						) : ''}
						{(page.splitContent) ? (
							<SplitBlock splitContent={page.splitContent} location={props.location} />
						) : ''}
					</div>
				</Col>
			</Row>
		</Layout>
	)
}

export default WelfarePage

export const query = graphql`
	query($id: String!) {
      wpPage(id: { eq: $id }) {
        id
		title
		slug
		heroBanner {
		  bannerContent
		  bannerLayout
		  bannerTextColour
		  bannerTitle
		  showBanner
		  bannerImage {
			altText
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
		contentBlock {
		  contentContent
		  contentSubtitle
		  contentTitle
		  showContentBlock
		}
		ctas {
		  ctas {
			ctaContent
			ctaTitle
			ctaLink {
			  url
			  title
			  target
			}
			ctaIcon {
			  altText
			  localFile {
			    publicURL
				childrenImageSharp {
				  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
				}
			  }
			}
		  }
		}
		faqs {
		  showFaqs
		  faqs {
			answer
			question
		  }
		}
		leaseOptions {
		  leaseOptions {
			leaseOptionContent
			fieldGroupName
			leaseOptionTitle
			leaseOptionIcon {
			  altText
			  localFile {
			    publicURL
				childImageSharp {
				  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
				}
			  }
			}
		  }
		  fieldGroupName
		}
		pricing {
		  pricingOptions {
			fieldGroupName
			pricingContent
			pricingPrice
			pricingTitle
		  }
		  pricingOptionsBlockText
		  fieldGroupName
		}
		splitContent {
		  splitLeftContent
		  splitLeftTitle
		  splitRightContent
		  splitRightTitle
		}
		seo {
		  metaDesc
		  metaKeywords
		  metaRobotsNoindex
		  metaRobotsNofollow
		  opengraphAuthor
		  opengraphDescription
		  opengraphModifiedTime
		  opengraphPublishedTime
		  opengraphPublisher
		  opengraphSiteName
		  opengraphTitle
		  opengraphType
		  opengraphUrl
		  readingTime
		  title
		  twitterTitle
		  schema {
			articleType
			pageType
			raw
		  }
		  twitterDescription
		  twitterImage {
			localFile {
			  childImageSharp {
				gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
			  }
			}
		  }
		}
      }
    }
`
